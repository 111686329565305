import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function main({t, site, localizePath, gaEvent}: NavOptions) {
  let navItems = [];

  navItems.push(
    {
      content: t('partners:nav.servicePartner'),
      url: isPublishedOnSite('/partners/service-partners', site)
        ? localizePath('/partners/service-partners')
        : '/partners/service-partners',
      data: {
        gaEvent,
        gaAction: 'Nav - Service Partner',
      },
      description: t('partners:nav.servicePartnerDesc'),
      columnSpan: 4,
      icon: 'service',
    },
    {
      content: t('partners:nav.techPartner'),
      url: isPublishedOnSite('/partners/technology-partners', site)
        ? localizePath('/partners/technology-partners')
        : '/partners/technology-partners',
      data: {
        gaEvent,
        gaAction: 'Nav - Technology Partner',
      },
      description: t('partners:nav.techPartnerDesc'),
      columnSpan: 4,
      icon: 'tech',
    },
  );

  return {
    simple: true,
    columnStart: 1,
    columnSpan: 12,
    navItems,
  };
}
