import cn from 'classnames';

import Anchor from '@/components/shared/Anchor/Anchor';
import {useTranslations} from '@/hooks/useTranslations';

interface Props {
  darkBackground: boolean;
}

export default function LogInButton({darkBackground}: Props) {
  const {t} = useTranslations();

  return (
    <Anchor
      className={cn(
        'text-black hover:text-black flex items-center hover:underline',
        {
          'text-white hover:text-white': darkBackground,
        },
      )}
      href="https://partners.shopify.com/organizations"
    >
      {t('partners:nav.logIn')}
    </Anchor>
  );
}
