import type {NavOptions} from '@/hooks/navigation/types';

import main from './main';

export default function wayToPartner(options: NavOptions) {
  const {t, gaEvent} = options;
  return {
    content: t('partners:nav.wayToPartner'),
    data: {
      gaEvent,
      gaAction: 'Nav - Way to Partner',
    },
    navItems: [main(options)],
  };
}
