import {type MetaFunction, Outlet, useLocation} from '@remix-run/react';

import partnersNextStyleSheet from '@/stylesheets/partners/next/partnersNext.css?url';
import PageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {ColorSchemeEnum} from '@/enums';

import PartnersHeader from './components/Header/Header';

export {loader} from '@/utils/server/loader/loader';

// Add a path here to opt into the partnersNext.css
const partnersNextPaths = [
  '',
  'partners',
  'find-a-partner',
  'education',
  'technology-partners',
  'service-partners',
  'styleguide', // temporary path
  'shared', // temporary path
];

export const meta: MetaFunction = (data: any) => {
  return partnersNextPaths.includes(data.location.pathname.split('/').pop())
    ? [
        {
          tagName: 'link',
          rel: 'stylesheet',
          href: partnersNextStyleSheet,
        },
      ]
    : [];
};

export default function PartnersNextLayout() {
  // TODO: only render custom header & footer for paths in partnersNextPaths array
  const location = useLocation();
  const formattedPathName = location.pathname.split('/').pop() || '';

  return partnersNextPaths.includes(formattedPathName) ? (
    <PageLayout
      footer={
        <Footer colorScheme={ColorSchemeEnum.Dark} className="bg-true-black" />
      }
      header={PartnersHeader}
    >
      <Outlet />
    </PageLayout>
  ) : (
    <>
      <Outlet />
    </>
  );
}
