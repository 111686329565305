import cn from 'classnames';

import Button from '@/components/base/elements/Button/Button';
import type {ButtonProps} from '@/components/base/elements/Button/Button';
import {useTranslations} from '@/hooks/useTranslations';

interface Props {
  darkBackground: boolean;
  size?: ButtonProps['size'];
  className?: string;
}

export default function JoinButton({
  darkBackground,
  size = 'small',
  className,
}: Props) {
  const {t} = useTranslations();
  const contactText = t('partners:nav.join');

  return (
    <Button
      href="https://partners.shopify.com/signup"
      componentName="join-button"
      size={size}
      mode="dark"
      intent={darkBackground ? 'primary' : 'secondary'}
      className={cn(
        `${className}`,
        'whitespace-nowrap text-black border-0',
        {
          'bg-black text-white hover:border-black hover:ring-black active:ring-black hover:text-black active:border-black active:text-black ':
            !darkBackground,
        },
        {
          'text-black bg-[rgb(241,245,242)] hover:ring-1 hover:bg-transparent hover:ring-white hover:text-white hover:border-white active:ring-white active:border-white active:text-white':
            darkBackground,
        },
      )}
    >
      {contactText}
    </Button>
  );
}
