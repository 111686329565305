import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function stories({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [
    {
      content: t('partners:nav.resourcesConvertDigital'),
      description: t('partners:nav.resourcesConvertDigitalDesc'),
      url: isPublishedOnSite(
        '/partners/blog/how-convert-digital-grew-their-business-by-building-a-shopify-practice',
        site,
      )
        ? localizePath(
            '/partners/blog/how-convert-digital-grew-their-business-by-building-a-shopify-practice',
          )
        : '/partners/blog/how-convert-digital-grew-their-business-by-building-a-shopify-practice',
      data: {
        gaEvent,
        gaAction: 'Nav - Convert Digital',
      },
    },
    {
      content: t('partners:nav.resourcesAvex'),
      description: t('partners:nav.resourcesAvexDesc'),
      url: isPublishedOnSite(
        '/partners/blog/how-avex-grew-500-over-3-years-by-switching-to-shopify',
        site,
      )
        ? localizePath(
            '/partners/blog/how-avex-grew-500-over-3-years-by-switching-to-shopify',
          )
        : '/partners/blog/how-avex-grew-500-over-3-years-by-switching-to-shopify',
      data: {
        gaEvent,
        gaAction: 'Nav - Avex',
      },
    },
    {
      content: t('partners:nav.resourcesDomaine'),
      description: t('partners:nav.resourcesDomaineDesc'),
      url: isPublishedOnSite(
        '/partners/blog/domaine-doubles-their-client-base-and-team-by-building-a-shopify-practice',
        site,
      )
        ? localizePath(
            '/partners/blog/domaine-doubles-their-client-base-and-team-by-building-a-shopify-practice',
          )
        : '/partners/blog/domaine-doubles-their-client-base-and-team-by-building-a-shopify-practice',
      data: {
        gaEvent,
        gaAction: 'Nav - Domaine',
      },
    },
    {
      content: t('partners:nav.resourcesP3Media'),
      description: t('partners:nav.resourcesP3MediaDesc'),
      url: isPublishedOnSite(
        '/partners/blog/p3-media-unlocks-enterprise-growth-with-shopify',
        site,
      )
        ? localizePath(
            '/partners/blog/p3-media-unlocks-enterprise-growth-with-shopify',
          )
        : '/partners/blog/p3-media-unlocks-enterprise-growth-with-shopify',
      data: {
        gaEvent,
        gaAction: 'Nav - P3 Media',
      },
    },
  ];

  return {
    content: t('partners:nav.resourcesPartnerStories'),
    data: {
      gaEvent,
      gaAction: 'Nav - Partner Stories',
    },
    icon: 'partner',
    columnStart: 1,
    columnSpan: 4,
    navItems,
  };
}
