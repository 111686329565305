import {useLocation} from '@remix-run/react';

import {HeaderBackgroundColorScheme} from '@/enums';
import Header from '@/pages/shopify.com/($locale)/enterprise/components/Header/Header';
import type {I18NOptions} from '@/types';
import type {NavOptions} from '@/hooks/navigation/types';

import resources from './hooks/resources';
import ShopifyPartnersLogo from './components/ShopifyPartnersLogo/ShopifyPartnersLogo';
import wayToPartner from './hooks/wayToPartner';
import CustomCtaLink from './components/CustomDesktopCtaList/CustomDesktopCtaList';
import MobileMenuCtaLinklist from './components/CustomMobileMenuCtaList/CustomMobileMenuCtaList';

const darkThemePages = ['technology-partners', 'service-partners'];

const defaultMainNav = ({t, localizePath, site}: I18NOptions) => {
  const gaEvent = 'Main Nav';
  const options: NavOptions = {t, localizePath, site, gaEvent};

  const navItems: (
    | ReturnType<typeof wayToPartner>
    | ReturnType<typeof resources>
    | any
  )[] = [
    wayToPartner(options),
    {
      content: t('partners:nav.education'),
      url: localizePath('/partners/education'),
      data: {
        gaEvent,
        gaAction: 'Nav - Education',
      },
    },
    resources(options),
    {
      content: t('partners:nav.findPartner'),
      url: localizePath('/partners/find-a-partner'),
      data: {
        gaEvent,
        gaAction: 'Nav - Find Partner',
      },
    },
  ];

  return navItems;
};

export default function PartnersHeader() {
  const location = useLocation();
  const formattedPathName = location.pathname.split('/').pop() || '';
  const darkThemePage = darkThemePages.includes(formattedPathName);
  const background = darkThemePage
    ? HeaderBackgroundColorScheme.TransparentDark
    : HeaderBackgroundColorScheme.TransparentLight;
  return (
    <Header
      background={background}
      className="bg-[rgba(255,255,255,0.01)] [&>div]:backdrop-blur-[5px]"
      mainNavFn={defaultMainNav}
      logo={ShopifyPartnersLogo}
      CustomDesktopCtaLinklist={CustomCtaLink}
      CustomMobileCtaLinklist={CustomCtaLink}
      CustomMobileMenuCtaLinklist={MobileMenuCtaLinklist}
      withBottomBar={false}
    />
  );
}
