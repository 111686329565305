import JoinButton from '../JoinButton/JoinButton';
import LogInButton from '../LogInButton/LogInButton';

interface Props {
  darkBackground: boolean;
}

export default function CustomCtaLink({darkBackground}: Props) {
  return (
    <>
      <li className="pr-8 hidden lg:block">
        <LogInButton darkBackground={darkBackground} />
      </li>
      <li className="leading-[0]">
        <JoinButton darkBackground={darkBackground} />
      </li>
    </>
  );
}
