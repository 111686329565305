import {useTranslations} from '@/components/shared/Page/I18N';

import {WhiteShopifyPartnersLogo} from './WhiteShopifyPartnersLogo';
import {DarkShopifyPartnersLogo} from './DarkShopifyPartnersLogo';

interface ShopifyLogoProps {
  isLightBackground?: boolean;
  isOpenMenu?: boolean;
}

export default function ShopifyPartnersLogo({
  isLightBackground,
  isOpenMenu,
}: ShopifyLogoProps) {
  const {t, localizePath} = useTranslations();
  return (
    <div className="mr-8 shrink-0">
      <a
        href={localizePath('/partners')}
        data-component-name="logo-home"
        aria-label={t('global:ariaLabels.shopifyPartnersLogo')}
      >
        {isLightBackground || isOpenMenu ? (
          <DarkShopifyPartnersLogo />
        ) : (
          <WhiteShopifyPartnersLogo />
        )}
      </a>
    </div>
  );
}
