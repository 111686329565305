import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function news({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [
    {
      content: t('partners:nav.resourcesGoogleCloud'),
      description: t('partners:nav.resourcesGoogleCloudDesc'),
      url: 'https://shopifypartnerblog.myshopify.com/blogs/blog/empowering-innovation-shopify-and-google-cloud-partner-to-meet-customer-needs',
      data: {
        gaEvent,
        gaAction: 'Nav - Google Cloud',
      },
    },
    {
      content: t('partners:nav.resourcesAstound'),
      description: t('partners:nav.resourcesAstoundDesc'),
      url: isPublishedOnSite('/partners/blog/astound-digital-partnership', site)
        ? localizePath('/partners/blog/astound-digital-partnership')
        : '/partners/blog/astound-digital-partnership',
      data: {
        gaEvent,
        gaAction: 'Nav - Astound',
      },
    },
    {
      content: t('partners:nav.resourcesOracle'),
      description: t('partners:nav.resourcesOracleDesc'),
      url: isPublishedOnSite('/enterprise/blog/oracle-partnership', site)
        ? localizePath('/enterprise/blog/oracle-partnership')
        : '/enterprise/blog/oracle-partnership',
      data: {
        gaEvent,
        gaAction: 'Nav - Oracle',
      },
    },
  ];

  return {
    content: t('partners:nav.resourcesNews'),
    data: {
      gaEvent,
      gaAction: 'Nav - News & Events',
    },
    icon: 'news',
    columnStart: 5,
    columnSpan: 4,
    navItems,
  };
}
