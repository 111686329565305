import type {NavOptions} from '@/hooks/navigation/types';

import stories from './stories';
import news from './news';
import links from './links';

export default function resources(options: NavOptions) {
  const {t, gaEvent} = options;
  return {
    content: t('partners:nav.resources'),
    data: {
      gaEvent,
      gaAction: 'Resources Nav',
    },
    navItems: [stories(options), news(options), links(options)],
  };
}
