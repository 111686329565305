export const WhiteShopifyPartnersLogo = () => (
  <svg
    width="169"
    height="28"
    viewBox="0 0 169 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M99.7344 27.4013L102.067 14.9635C102.36 13.4029 102.578 12.0183 102.724 10.9472H103.722L103.358 13.3079H103.406C104.453 11.726 105.914 10.7039 107.471 10.7039C109.687 10.7039 110.514 12.6021 110.514 14.501C110.514 18.4443 107.812 22.8714 104.088 22.8714C102.943 22.8714 102.189 22.6039 101.751 22.3606H101.702L100.78 27.397L99.7344 27.4013ZM101.925 21.2916C102.46 21.7052 103.239 21.9485 104.188 21.9485C107.28 21.9485 109.422 17.5675 109.422 14.5712C109.422 13.1838 108.886 11.6501 107.158 11.6501C105.503 11.6501 103.336 13.7434 102.68 17.2732L101.925 21.2916Z"
      fill="#F1F5F2"
    />
    <path
      d="M118.572 22.6302C118.547 21.9244 118.693 20.8291 118.864 19.4906H118.817C117.478 22.0705 115.993 22.8735 114.459 22.8735C112.686 22.8735 111.611 21.4129 111.611 19.3196C111.611 15.3522 114.459 10.7031 118.989 10.7031C119.816 10.7031 120.741 10.8493 121.349 11.0436L120.108 17.3483C119.694 19.5147 119.548 21.3916 119.597 22.6302H118.572ZM120.133 11.8225C119.865 11.7253 119.451 11.6522 118.72 11.6522C115.362 11.6522 112.733 15.4012 112.709 19.0763C112.684 20.5858 113.122 21.9485 114.704 21.9485C116.603 21.9485 118.747 19.1011 119.305 16.0821L120.133 11.8225Z"
      fill="#F1F5F2"
    />
    <path
      d="M122.344 22.6302L123.829 14.7677C124.121 13.283 124.292 11.9686 124.389 10.9464H125.362C125.265 11.7012 125.168 12.4801 125.022 13.4781H125.071C125.8 11.9175 126.945 10.7031 128.404 10.7031C128.552 10.7045 128.699 10.7207 128.843 10.7514L128.648 11.8473C128.512 11.8158 128.373 11.7994 128.234 11.7984C126.701 11.7984 124.997 14.1109 124.509 16.6929L123.414 22.6323L122.344 22.6302Z"
      fill="#F1F5F2"
    />
    <path
      d="M133.273 8.36646L132.762 10.9471H135.512L135.318 11.848H132.567L131.276 18.8095C131.131 19.5898 131.033 20.197 131.033 20.8298C131.033 21.5115 131.35 21.925 132.08 21.925C132.47 21.925 132.713 21.9009 132.931 21.8278L133.002 22.679C132.631 22.7903 132.246 22.8476 131.858 22.8493C130.471 22.8493 129.935 21.9271 129.935 20.9511C129.935 20.3176 130.006 19.709 130.203 18.8088L131.518 11.8472H129.838L130.008 10.9464H131.711L132.1 8.85307L133.273 8.36646Z"
      fill="#F1F5F2"
    />
    <path
      d="M135.244 22.6302L136.805 14.3783C137.041 13.2441 137.22 12.0987 137.34 10.9464H138.314L137.995 13.332H138.044C139.066 11.6295 140.527 10.7031 142.109 10.7031C143.253 10.7031 144.713 11.433 144.713 13.6484C144.713 14.2321 144.616 14.8167 144.494 15.4494L143.156 22.6302H142.109L143.472 15.4494C143.58 14.8883 143.637 14.3184 143.642 13.747C143.642 12.5297 143.156 11.6537 141.719 11.6537C140.159 11.6537 137.971 13.6008 137.463 16.4248L136.319 22.6316L135.244 22.6302Z"
      fill="#F1F5F2"
    />
    <path
      d="M153.282 21.9733C152.674 22.3627 151.554 22.8494 150.094 22.8494C147.367 22.8494 146.418 20.7071 146.418 18.4677C146.418 15.1089 148.755 10.7031 152.114 10.7031C154.037 10.7031 154.84 11.9686 154.84 13.2568C154.84 16.2261 151.435 17.0539 147.61 17.0298C147.489 17.5654 147.469 19.099 147.708 19.9261C148.122 21.2654 149.023 21.8981 150.262 21.8981C151.552 21.8981 152.428 21.4356 152.964 21.1178L153.282 21.9733ZM151.919 11.6522C150.118 11.6522 148.487 13.5994 147.781 16.107C150.824 16.1311 153.745 15.6196 153.745 13.3072C153.745 12.2119 153.014 11.6522 151.919 11.6522Z"
      fill="#F1F5F2"
    />
    <path
      d="M155.424 22.6302L156.909 14.7677C157.201 13.283 157.372 11.9686 157.469 10.9464H158.443C158.345 11.7012 158.248 12.4801 158.102 13.4781H158.151C158.88 11.9175 160.025 10.7031 161.485 10.7031C161.632 10.7045 161.779 10.7207 161.923 10.7514L161.728 11.8473C161.592 11.8158 161.454 11.7994 161.314 11.7984C159.781 11.7984 158.077 14.1109 157.59 16.6929L156.494 22.6323L155.424 22.6302Z"
      fill="#F1F5F2"
    />
    <path
      d="M161.435 21.2675C162.051 21.6711 162.769 21.8907 163.504 21.9002C165.038 21.9002 166.058 20.805 166.058 19.4884C166.058 18.3194 165.571 17.6136 164.476 16.9078C163.332 16.153 162.699 15.1067 162.699 14.0356C162.699 12.2097 164.159 10.7017 166.204 10.7017C167.007 10.7017 167.811 10.9691 168.151 11.2372L167.761 12.1388C167.396 11.9196 166.763 11.6522 166.081 11.6522C164.67 11.6522 163.745 12.6261 163.745 13.8427C163.745 14.9386 164.354 15.5451 165.327 16.1835C166.447 16.9383 167.129 18.0087 167.129 19.1777C167.129 21.5384 165.401 22.8529 163.404 22.8529C162.357 22.8529 161.432 22.4634 161.043 22.147L161.435 21.2675Z"
      fill="#F1F5F2"
    />
    <path
      d="M21.1174 5.25676C21.1083 5.19797 21.0797 5.14393 21.0362 5.1033C20.9928 5.06266 20.9369 5.03777 20.8776 5.03261C20.7783 5.0248 18.8333 4.99501 18.8333 4.99501C18.8333 4.99501 17.2067 3.4167 17.0464 3.25923C16.8861 3.10175 16.5719 3.14786 16.4499 3.18333L15.6327 3.43657C15.5319 3.10495 15.4011 2.78323 15.2418 2.47539C14.6623 1.37093 13.8146 0.785718 12.7903 0.784299H12.7868C12.7158 0.784299 12.6449 0.791393 12.574 0.797777C12.5442 0.7616 12.5137 0.726842 12.481 0.690665C12.0356 0.213271 11.4624 -0.0186865 10.7786 0.00117532C9.45282 0.0352242 8.13556 0.989302 7.06656 2.69175C6.31323 3.88346 5.74575 5.38728 5.58047 6.54565L2.97431 7.3529C2.2075 7.59337 2.18338 7.61748 2.08266 8.34031C2.00676 8.88722 0 24.4071 0 24.4071L16.8201 27.3155L24.1102 25.5038C24.1102 25.5038 21.1387 5.39509 21.1174 5.25676ZM14.79 3.69619L13.4848 4.09981C13.4748 3.43018 13.3954 2.49738 13.0826 1.69156C14.087 1.88025 14.5814 3.01592 14.79 3.69406V3.69619ZM12.6052 4.3722L9.79686 5.23974C10.0685 4.20054 10.5828 3.16559 11.2156 2.48816C11.481 2.1922 11.8058 1.95556 12.1689 1.79371C12.5342 2.55697 12.6137 3.63944 12.6052 4.37007V4.3722ZM10.802 0.877934C11.0825 0.863222 11.3607 0.936103 11.5979 1.08648C11.2119 1.29286 10.864 1.56372 10.5693 1.88734C9.72876 2.78893 9.08467 4.18919 8.82788 5.53979L6.52604 6.25411C6.97718 4.12747 8.75908 0.93681 10.802 0.877934Z"
      fill="#95BF47"
    />
    <path
      d="M20.8771 5.03268C20.7778 5.02488 18.8327 4.99509 18.8327 4.99509C18.8327 4.99509 17.2062 3.41678 17.0459 3.2593C16.9837 3.2014 16.9047 3.16487 16.8203 3.15503V27.3155L24.1096 25.5039C24.1096 25.5039 21.1381 5.39516 21.1169 5.25684C21.1077 5.19804 21.0791 5.14401 21.0357 5.10337C20.9922 5.06274 20.9364 5.03785 20.8771 5.03268Z"
      fill="#5E8E3E"
    />
    <path
      d="M12.7804 8.78324L11.9335 11.9505C11.2803 11.68 10.5754 11.5572 9.86924 11.5909C8.22851 11.6944 8.21007 12.7301 8.22851 12.9897C8.31789 14.4084 12.0448 14.7156 12.2541 18.0339C12.4194 20.6443 10.8694 22.4319 8.63639 22.5737C5.95716 22.7426 4.48242 21.1621 4.48242 21.1621L5.0499 18.7503C5.0499 18.7503 6.53316 19.864 7.72132 19.7874C8.49735 19.7385 8.77542 19.1071 8.74704 18.661C8.63 16.8167 5.59539 16.9216 5.40387 13.8856C5.24285 11.3319 6.92046 8.74139 10.6233 8.50731C12.0498 8.41864 12.7804 8.78324 12.7804 8.78324Z"
      fill="white"
    />
    <path
      d="M33.9879 15.5417C33.1431 15.0834 32.7111 14.6968 32.7111 14.1655C32.7111 13.4895 33.3148 13.0554 34.2568 13.0554C35.3534 13.0554 36.333 13.5136 36.333 13.5136L37.0999 11.148C37.0999 11.148 36.3905 10.5925 34.2993 10.5925C31.391 10.5925 29.3736 12.2581 29.3736 14.6004C29.3736 15.9283 30.3156 16.9412 31.5726 17.6662C32.5862 18.2457 32.9487 18.6593 32.9487 19.2601C32.9487 19.8879 32.4415 20.3951 31.5002 20.3951C30.0971 20.3951 28.7721 19.6708 28.7721 19.6708L27.9492 22.0365C27.9492 22.0365 29.1736 22.8572 31.2328 22.8572C34.227 22.8572 36.3756 21.3846 36.3756 18.7288C36.3784 17.3044 35.2917 16.29 33.9879 15.5417Z"
      fill="#F1F5F2"
    />
    <path
      d="M45.915 10.5656C44.4424 10.5656 43.2833 11.2657 42.3903 12.3276L42.3413 12.3042L43.6181 5.61646H40.292L37.0566 22.6409H40.3906L41.5007 16.8242C41.9356 14.6252 43.0705 13.2775 44.1324 13.2775C44.8808 13.2775 45.1709 13.7846 45.1709 14.5089C45.1706 15.0033 45.1219 15.4966 45.0255 15.9815L43.7678 22.6402H47.1018L48.4048 15.7595C48.549 15.0435 48.6299 14.3162 48.6467 13.586C48.6432 11.7006 47.6529 10.5656 45.915 10.5656Z"
      fill="#F1F5F2"
    />
    <path
      d="M56.1759 10.5657C52.1681 10.5657 49.5137 14.1898 49.5137 18.2217C49.5137 20.8052 51.1069 22.8814 54.101 22.8814C58.0365 22.8814 60.6916 19.3567 60.6916 15.2282C60.6902 12.8356 59.29 10.5657 56.1759 10.5657ZM54.5345 20.3193C53.3995 20.3193 52.9164 19.3538 52.9164 18.1465C52.9164 16.2391 53.9095 13.125 55.717 13.125C56.9002 13.125 57.286 14.1387 57.286 15.1289C57.286 17.1839 56.2965 20.3221 54.5345 20.3221V20.3193Z"
      fill="#F1F5F2"
    />
    <path
      d="M69.2113 10.5657C66.962 10.5657 65.6851 12.5519 65.6851 12.5519H65.6369L65.8298 10.765H62.886C62.7442 11.9709 62.4753 13.8074 62.21 15.1836L59.8926 27.3716H63.2265L64.1437 22.4466H64.2147C64.2147 22.4466 64.8985 22.8814 66.1703 22.8814C70.0817 22.8814 72.6403 18.8736 72.6403 14.8175C72.6368 12.5724 71.6501 10.5657 69.2113 10.5657ZM66.0249 20.3703C65.5235 20.3777 65.0358 20.2068 64.6488 19.888L65.2042 16.7732C65.5901 14.6969 66.6768 13.3208 67.8352 13.3208C68.8496 13.3208 69.1631 14.2621 69.1631 15.1559C69.161 17.3045 67.8841 20.3703 66.0249 20.3703Z"
      fill="#F1F5F2"
    />
    <path
      d="M77.3972 5.78785C77.1445 5.78706 76.8942 5.83667 76.6608 5.93377C76.4275 6.03088 76.2159 6.17352 76.0384 6.35337C75.8608 6.53322 75.7209 6.74667 75.6269 6.98122C75.5328 7.21577 75.4864 7.46673 75.4905 7.71942C75.4905 8.71251 76.1183 9.38497 77.0596 9.38497H77.1078C78.1463 9.38497 79.0394 8.68484 79.0635 7.45341C79.0635 6.48798 78.4116 5.78785 77.3972 5.78785Z"
      fill="#F1F5F2"
    />
    <path
      d="M72.7383 22.6399H76.0701L78.3393 10.8335H74.9834L72.7383 22.6399Z"
      fill="#F1F5F2"
    />
    <path
      d="M86.8117 10.8095H84.4942L84.6148 10.2548C84.8078 9.11985 85.4838 8.10548 86.5946 8.10548C86.956 8.10607 87.315 8.16328 87.6587 8.27501L88.3084 5.67098C88.3084 5.67098 87.7289 5.38086 86.4974 5.38086C85.315 5.38086 84.1318 5.71922 83.2387 6.4917C82.1037 7.45713 81.5724 8.85739 81.3071 10.2584L81.2107 10.8131H79.6657L79.1826 13.3242H80.7276L78.9648 22.6436H82.2988L84.0573 13.3206H86.3506L86.8117 10.8095Z"
      fill="#F1F5F2"
    />
    <path
      d="M94.8282 10.8335C94.8282 10.8335 92.7455 16.0827 91.8099 18.9456H91.7624C91.6978 18.0235 90.941 10.8335 90.941 10.8335H87.4403L89.4442 21.6738C89.4925 21.915 89.4683 22.0604 89.3733 22.2292C88.9867 22.9776 88.3348 23.7018 87.5623 24.2331C86.9345 24.6921 86.2344 24.9815 85.6797 25.1745L86.5969 27.9998C87.2729 27.8579 88.6732 27.299 89.8599 26.1888C91.3808 24.7645 92.781 22.5669 94.2295 19.5734L98.3097 10.8335H94.8282Z"
      fill="#F1F5F2"
    />
  </svg>
);
