import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function links({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [
    {
      content: t('partners:nav.resourcesPartner'),
      description: t('partners:nav.resourcesPartnerDesc'),
      url: isPublishedOnSite('/partners/blog', site)
        ? localizePath('/partners/blog')
        : '/partners/blog',
      data: {
        gaEvent,
        gaAction: 'Nav - Partner Blog',
      },
    },
    {
      content: t('partners:nav.resourcesShopifyNews'),
      description: t('partners:nav.resourcesShopifyNewsDesc'),
      url: isPublishedOnSite('/partners/blog/topics/shopify-news', site)
        ? localizePath('/partners/blog/topics/shopify-news')
        : '/partners/blog/topics/shopify-news',
      data: {
        gaEvent,
        gaAction: 'Nav - Shopify News',
      },
    },
    {
      content: t('partners:nav.resourcesAcademy'),
      description: t('partners:nav.resourcesAcademyDesc'),
      url: 'https://academy.shopify.com/',
      data: {
        gaEvent,
        gaAction: 'Nav - Shopify Academy',
      },
    },
  ];

  return {
    content: t('partners:nav.resourcesLinks'),
    data: {
      gaEvent,
      gaAction: 'Nav - Quick Links',
    },
    icon: 'link',
    columnStart: 9,
    columnSpan: 4,
    navItems,
  };
}
