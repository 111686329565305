import useGlobalNav from '@/hooks/navigation/global';
import Button from '@/components/base/elements/Button/Button';

import JoinButton from '../JoinButton/JoinButton';

interface Props {
  darkBackground: boolean;
}

export default function MobileMenuCtaLinklist({darkBackground}: Props) {
  const {loginText} = useGlobalNav();
  return (
    <>
      <li className="py-1 sm:grow sm:pt-2">
        <Button
          href="https://partners.shopify.com/organizations"
          intent="secondary"
          className="w-full text-black border-black"
          mode="light"
          componentName="login"
          size="small"
        >
          {loginText}
        </Button>
      </li>
      <li className="pt-1 sm:grow sm:pt-2">
        <JoinButton darkBackground={darkBackground} className="w-full" />
      </li>
    </>
  );
}
